import { clsx } from 'clsx'
import { type FC } from 'react'

import { type AlgoliaRecord } from '../../../algolia'
import { SliderButton, useSliderNavigation } from '../../../slider'
import { StoryblokProductBatteryItem } from '../StoryblokProductBattery/StoryblokProductBatteryItem'

import styles from './StoryblokProductBatterySlider.module.css'

type StoryblokProductBatterySliderProps = {
  products: AlgoliaRecord[]
}

export const StoryblokProductBatterySlider: FC<
  StoryblokProductBatterySliderProps
> = ({ products }) => {
  const { containerRef, leftRef, rightRef } =
    useSliderNavigation<HTMLUListElement>()

  return (
    <div className={styles.slider}>
      <ul className={styles.slider__container} ref={containerRef}>
        {products.map((product) => (
          <li className={styles.slider__item} key={product.objectID}>
            <StoryblokProductBatteryItem product={product} />
          </li>
        ))}
      </ul>
      <SliderButton
        aria-label="previous"
        className={clsx(styles['slider__navigation-button'], 'left-0')}
        direction="left"
        ref={leftRef}
      />
      <SliderButton
        aria-label="next"
        className={clsx(styles['slider__navigation-button'], 'right-0')}
        direction="right"
        ref={rightRef}
      />
    </div>
  )
}

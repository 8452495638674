'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconArrowRight } from '@redteclab/icons'

import { Heading, Link } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokProductBatteryV2Type } from '../../model/storyblokTypes.generated'
import { useStoryblokServerSideData } from '../../storyblok-server-side-data/context/storyblokServerSideDataContext'

import { StoryblokProductBatteryGrid } from './StoryblokProductBatteryGrid'
import { StoryblokProductBatterySlider } from './StoryblokProductBatterySlider'

export const StoryblokProductBatteryV2: FC<
  StoryblokComponentProps<StoryblokProductBatteryV2Type>
> = ({ blok }) => {
  const { data, error } = useStoryblokServerSideData(blok)

  if (error || !data || data.products.length === 0) {
    return null
  }

  const { layout, title } = blok
  const { allProductsLink, products } = data

  return (
    <div className="w-full" {...storyblokEditable(blok)}>
      <div className="mb-4 flex flex-col items-start justify-between gap-1 tablet:flex-row tablet:items-center">
        <Heading level={2} size="l">
          {title}
        </Heading>
        {allProductsLink !== null && (
          <Link
            className="flex items-center gap-1 whitespace-nowrap text-s font-medium no-underline tablet:text-m"
            color="inherit"
            href={allProductsLink}
          >
            <FormattedMessage id="general.label.ShowAllProducts" />
            <IconArrowRight className="size-6 text-dark-brand" />
          </Link>
        )}
      </div>
      {layout === 'slider' && (
        <StoryblokProductBatterySlider products={products} />
      )}
      {layout === 'grid' && <StoryblokProductBatteryGrid products={products} />}
    </div>
  )
}

'use client'

import { type ComponentProps, type FC } from 'react'

import screens from 'tailwind-preset/screens.cjs'

import {
  type BrandIllustrationName,
  type BrandIllustrationType,
  useBrandIllustrationImageProps,
} from '../../../brand-illustration'

type ResponsiveBrandIllustrationProps = ComponentProps<'picture'> & {
  name: BrandIllustrationName
  type: BrandIllustrationType
}

export const StoryblokResponsiveBrandIllustration: FC<
  ResponsiveBrandIllustrationProps
> = ({ name, type, ...pictureProps }) => {
  const mobileImageProps = useBrandIllustrationImageProps({
    name,
    size: 's',
    type,
  })

  const tabletImageProps = useBrandIllustrationImageProps({
    name,
    size: 'm',
    type,
  })

  return (
    <picture {...pictureProps}>
      <source
        height={tabletImageProps.height}
        media={`(min-width: ${screens.tablet})`}
        srcSet={tabletImageProps.srcSet}
        width={tabletImageProps.width}
      />
      <img {...mobileImageProps} alt="" />
    </picture>
  )
}
